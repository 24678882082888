import { Conversation } from './../../../entities/conversation.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Note } from 'src/app/entities/note.model';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  crypto_support: boolean = false;

  constructor(
    private http: GenericHttpService,
    private sharedService: SharedService
  ) {
    this.crypto_support = window.hasOwnProperty('crypto');
  }

  getPublicKey = (user_id: number): Observable<any> =>
    this.http.get(`/public_key/${user_id}`);

  getConversationKey(conversation) {
    return this.http.get(`/ck/${conversation.id}`);
  }

  digestVector(key: string) {
    var ivstr = key.substr(0, 8);
    return this.convertStringToArrayBufferView(ivstr + ivstr);
  }

  digestKey(key: string) {
    var that = this;
    return new Promise<CryptoKey>(function (resolve, reject) {
      if (!key) {
        reject('No key');
        return;
      }
      crypto.subtle
        .digest({ name: 'SHA-256' }, that.convertStringToArrayBufferView(key))
        .then(function (result) {
          window.crypto.subtle
            .importKey('raw', result, 'AES-CBC', false, ['encrypt', 'decrypt'])
            .then(
              function (e) {
                resolve(e);
              },
              function (e) {
                reject(e.message);
              }
            );
        });
    });
  }

  encrypt = (body: string, key: string): Promise<string> => {
    var that = this;

    if (body) {
      body = this.encodeEmoji(body);
    }

    var p = new Promise<string>(function (resolve, reject) {
      if (!body || !key || !that.crypto_support) {
        MonitoringService.captureException(
          new Error(
            `not encryptable has body:${!!body},  has key:${!!key}, has crypto_support:${
              that.crypto_support
            }`
          )
        );
        resolve('not encryptable');
      }

      that
        .digestKey(key)
        .then((digestedkey) => {
          crypto.subtle
            .encrypt(
              { name: 'AES-CBC', iv: that.digestVector(key) },
              digestedkey,
              that.convertStringToArrayBufferView(body)
            )
            .then(
              function (result) {
                var encrypted_data = new Uint8Array(result);
                var encrypted = that.buf2hex(encrypted_data);
                resolve(encrypted);
              },
              function (e) {
                resolve('');
              }
            );
        })
        .catch((err) => {
          MonitoringService.captureException(err);
          resolve('not encryptable');
        });
    });

    p.catch((err) => {
      alert(
        'An error occured during encryption. Please try again or contact support'
      );
    });

    return p;
  };

  decrypt = (body: string, key: string): Promise<string> => {
    var that = this;

    let p = new Promise<string>(function (resolve, reject) {
      if (!body || !key || !that.crypto_support) {
        resolve('');
      }

      that
        .digestKey(key)
        .then((digestedkey) => {
          crypto.subtle
            .decrypt(
              { name: 'AES-CBC', iv: that.digestVector(key) },
              digestedkey,
              that.hex2buf(body)
            )
            .then(
              function (result) {
                var decrypted_data = new Uint8Array(result);
                var decryptedBody =
                  that.convertArrayBufferViewtoString(decrypted_data);
                decryptedBody = that.decodeEmoji(decryptedBody);
                resolve(decryptedBody);
              },
              function (e) {
                resolve('');
              }
            );
        })
        .catch((err) => {
          resolve('');
        });
    });

    p.catch((err) => {
      alert(
        'An error occured during decryption. Please try again or contact support'
      );
    });

    return p;
  };

  encodeEmoji(body) {
    try {
      var b = unescape(encodeURIComponent(body));
      return b;
    } catch (e) {
      return body;
    }
  }

  decodeEmoji(body) {
    try {
      var b = decodeURIComponent(escape(body));
      return b;
    } catch (e) {
      return body;
    }
  }

  decryptLastMessage(conversations: Conversation[]) {
    return new Promise((resolve, reject) => {
      var counter = 0;
      conversations.forEach((conversation) => {
        var msg = conversation.messages[0];
        if (!msg.encrypted || msg.decrypted) {
          counter++;
          msg.decrypted = true;
          return;
        }
        this.getConversationKey(conversation).subscribe((data) => {
          conversation.key = data.key;
          this.decrypt(msg.body, data.key)
            .then((decrypted) => {
              msg.decrypted = true;
              msg.body = decrypted;
              counter++;
              if (counter == conversations.length) {
                resolve(true);
              }
            })
            .catch((err) => {
              alert(
                'An error occured during decryption. Please try again or contact support.'
              );
            });
        });
      });
    });
  }

  decryptConversation(conversation) {
    return new Promise((resolve, reject) => {
      this.getConversationKey(conversation).subscribe((data) => {
        conversation.key = data.key;

        if (!conversation.messages || conversation.messages.length == 0) {
          resolve(true);
          return;
        }
        var counter = 0;
        conversation.messages.forEach((msg) => {
          if (!msg.encrypted || msg.decrypted) {
            counter++;
            msg.decrypted = true;
            return;
          }

          this.decrypt(msg.body, data.key)
            .then((data) => {
              msg.body = data;
              msg.decrypted = true;
              counter++;
              if (conversation.messages.length == counter) {
                resolve(true);
              }
            })
            .catch((err) => {
              alert(
                'An error occured during decryption. Please try again or contact support.'
              );
            });
        });
      });
    });
  }

  assertKey(conversation) {
    return new Promise((resolve, reject) => {
      if (!conversation.key) {
        this.getConversationKey(conversation).subscribe((data) => {
          conversation.key = data.key;
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  decryptNotes(notes, conversation) {
    return new Promise<Note[]>((resolve, reject) => {
      notes.forEach((note) => {
        this.decrypt(note.body, conversation.key)
          .then((data) => {
            note.body = data;
          })
          .catch((err) => {
            alert(
              'An error occured during decryption. Please try again or contact support.'
            );
          })
          .then(() => {
            this.decrypt(note.title, conversation.key)
              .then((data) => {
                note.title = data;
                resolve(notes);
              })
              .catch((err) => {
                alert(
                  'An error occured during decryption. Please try again or contact support.'
                );
              });
          });
      });
    });
  }

  encryptNote(note, conversation) {
    return new Promise<Note>((resolve, reject) => {
      this.assertKey(conversation).then(() => {
        this.encrypt(note.body, conversation.key)
          .then((data) => {
            note.body = data;
            this.encrypt(note.title, conversation.key)
              .then((data) => {
                note.title = data;
                resolve(note);
              })
              .catch((err) => {
                alert(
                  'An error occured during encryption. Please try again or contact support.'
                );
              });
          })
          .catch((err) => {
            alert(
              'An error occured during encryption. Please try again or contact support.'
            );
          });
      });
    });
  }

  encryptNoteWithKey(note, key) {
    return new Promise<Note>((resolve, reject) => {
      this.encrypt(note.body, key)
        .then((data) => {
          note.body = data;
          this.encrypt(note.title, key)
            .then((data) => {
              note.title = data;
              resolve(note);
            })
            .catch((err) => {
              alert(
                'An error occured during encryption. Please try again or contact support.'
              );
            });
        })
        .catch((err) => {
          alert(
            'An error occured during encryption. Please try again or contact support.'
          );
        });
    });
  }

  convertStringToArrayBufferView(str) {
    if (!str) {
      return null;
    }
    var bytes = new Uint8Array(str.length);
    for (var iii = 0; iii < str.length; iii++) {
      bytes[iii] = str.charCodeAt(iii);
    }

    return bytes;
  }

  convertArrayBufferViewtoString(buffer) {
    var str = '';
    for (var iii = 0; iii < buffer.byteLength; iii++) {
      str += String.fromCharCode(buffer[iii]);
    }

    return str;
  }

  buf2hex(buffer) {
    return Array.prototype.map
      .call(new Uint8Array(buffer), (x) => ('00' + x.toString(16)).slice(-2))
      .join('');
  }

  hex2buf(hex) {
    var bytes = new Uint8Array(Math.ceil(hex.length / 2));
    for (var i = 0; i < bytes.length; i++)
      bytes[i] = parseInt(hex.substr(i * 2, 2), 16);
    return bytes;
  }
}
