import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../entities/user.model';

import { Conversation } from '../../../entities/conversation.model';
import { SharedService } from '../../../shared/services/shared.service';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { Message } from 'src/app/entities/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(
    private _http: GenericHttpService,
    private sharedService: SharedService
  ) {
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  private currentUser: User;

  getConversations(
    page = 0,
    size = 5,
    sorting = 'name',
    archived = false
  ): Observable<any> {
    // Some genius decided to use a 1 based index, yet default to 0 everywhere. Don;t use the default. Use 1, until we fix this
    return this._http.get(
      `/conversations?page=${page}&pageSize=${size}&sort=${sorting}&archived=${archived}`
    );
  }

  searchConversations(term = '', sort): Observable<any> {
    return this._http.post(`/conversations/search`, {
      query: term,
      sort: sort,
    });
  }

  archiveConversation = (id: number, value: boolean): Observable<any> =>
    this._http.put(`/archive_conversation/${id}`, { value: value });

  getConversationByAmount(
    amountOfMessages,
    conversation_id: number
  ): Observable<any> {
    return this._http.get(
      `/conversation_amount/${conversation_id}/${amountOfMessages}`
    );
  }

  getConversation(recipient_id: number): Observable<Conversation> {
    return this._http.get(`/conversations/${recipient_id}`);
  }

  getMessage(recipient_id: number, message_id: number): Observable<Message> {
    return this._http.get(
      `/conversations/${recipient_id}/messages/${message_id}`
    );
  }

  getMedia(media_id: number): Observable<Array<string>> {
    return this._http.get(`/conversations/get-media/${media_id}`);
  }

  sendMessage(msg: Message, conversation_id: number): Observable<Message> {
    msg.user_id = this.currentUser.id;
    return this._http.post(
      '/conversations/' + conversation_id + '/messages',
      msg
    );
  }

  deleteMessage(msg_id: number, conversation_id: number): Observable<any> {
    return this._http.delete(
      '/conversations/' + conversation_id + '/messages/' + msg_id
    );
  }

  setMessageAsRead(
    conversation_id: number,
    message_id: number
  ): Observable<Message> {
    return this._http.put(
      '/conversations/' + conversation_id + '/messages/' + message_id,
      { read: true }
    );
  }

  getOtherUserByExternalId(external_id: string): Observable<User> {
    return this._http.get(`/users/external/${external_id}`);
  }
}
