export class Note {
  id: number;
  title: string;
  body: string;
  client_id: number; //can be removed?
  client_uid: string;
  profile_id: number;
  created_at: string;
  updated_at: string;
  template: NoteTemplate = NoteTemplate.free;
}

export enum NoteTemplate {
  free = 'free',
  soap = 'soap',
  birp = 'birp',
  dap = 'dap',
}
