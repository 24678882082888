import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeAgo',
  pure: false,
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | moment.Moment | string | number): string {
    return moment(value).fromNow();
  }
}
